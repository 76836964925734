// @flow
import * as React from 'react'

type Option = {
  label: string
  selected: boolean
}

type Output = {
  selectedOptions: Option[]
  selectableOptions: Option[]
}

export default function useFilterOptions(
  options: Option[],
  value: string[],
  hasQuery: boolean
): Output {
  const staticS = React.useMemo(() => '-' + value.join('-') + '-', [])

  const selectedOptions: Option[] = []
  const selectableOptions: Option[] = []

  if (hasQuery) return { selectedOptions, selectableOptions: options }

  for (let i = 0; i < options.length; i++) {
    if (staticS.includes('-' + options[i].label + '-')) selectedOptions.push(options[i])
    else selectableOptions.push(options[i])
  }

  return { selectedOptions, selectableOptions }
}
