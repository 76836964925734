// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useFilter } from 'modules/products'
import { FiCheck } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import colorCodes from 'utils/colorCodes'
import ty from 'utils/typographie'
import GemustertSvg from 'svg/Inline/Gemustert-Icon.inline.svg'

type Props = {
  identifier: string
}

export default function ColorFilter(props: Props) {
  const filter = useFilter(props.identifier, 'color')
  const selectedDict = React.useMemo(() => {
    let dict = {}
    for (let val of filter.data.value) dict[val] = true
    return dict
  }, [filter.data.value])
  return (
    <Wrapper className="ColorFilter">
      <div className="top">
        <div className="label">FARBE</div>
        <div className="selected">
          {filter.data.value.map((c) => (
            <span key={c}>{c}</span>
          ))}
        </div>
        {Boolean(filter.data.value.length) && (
          <div className="reset" onClick={filter.clear}>
            <MdClose />
          </div>
        )}
      </div>
      <div className="options">
        {filter.data.options
          .filter((opt) => colorCodes[opt])
          .sort((a, b) => (colorCodes[a].weight < colorCodes[b].weight ? -1 : 1))
          .map((opt) => (
            <div key={opt} className="circles" onClick={() => filter.toggleFilterOption(opt)}>
              {opt === 'gemustert' 
                ? (
                  <ColorOption>
                    {!!selectedDict[opt] || <GemustertSvg/>}
                    {!!selectedDict[opt] && <div className='checked'><FiCheck /></div>}
                  </ColorOption>
                )
                : (
                  <Option
                    key={opt}
                    color={colorCodes[opt].color}
                    selected={!!selectedDict[opt]}
                    background={colorCodes[opt].bg}
                    children={!!selectedDict[opt] && <FiCheck />}
                  />
                )
              }
              <p className="colorTitle">{opt}</p>
            </div>
          ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 12px;
  padding-bottom: 20px;

  > .top {
    display: flex;
    > .label {
      color: #262626;
      font-family: Raleway;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.4px;
      line-height: 20px;
    }

    > .selected {
      flex: 1;
      text-transform: uppercase;
      color: #555555;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 20px;
      min-height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      > span {
        margin-left: 5px;
        white-space: nowrap;
      }
    }

    > .reset {
      line-height: 30px;
      width: 30px;
      text-align: center;
      > svg {
        background: #eeeced;
        color: #262626;
        border-radius: 30px;
        padding: 3px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }
  > .options {
    margin-top: 10px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    > .circles {
      > .colorTitle {
        text-transform: uppercase;
        text-align: center;
        ${ty([11, 0, 13], 'Raleway', 'normal')}
      }
    }
  }
`

const Option = styled.div.attrs((props) => ({
  style: {
    background: props.background,
    color: props.color,
  },
}))`
  margin: 0 auto;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.selected ? '#555555' : 'lightgrey')};

  > svg {
    font-size: 16px;
    margin-top: 11px;
    margin-left: 11px;
  }
`

const ColorOption = styled.div`
  text-align: center;
  position: relative;

  > .checked {
    > svg {
      font-size: 16px;
      margin-top: 14px;
    }
  }
`