// @flow
import * as React from 'react'
import styled from 'styled-components'
import { FiSearch } from 'react-icons/fi'
import { FiSquare, FiCheckSquare } from 'react-icons/fi'
import useFilterOptions from './hooks/useFilterOptions'

type OptionType = {
  label: string
  selected: boolean
  tag?: string
}
type Props = {
  label: string
  query?: string
  onQuery?: (query: string) => void
  onClose: () => void
  onOptionClick: (opt: OptionType) => void
  options: OptionType[]
  value: string[]
  onResetClick: () => void
  closeLabel: string
  'data-cy-handle-reset-btn': 'reset-btn'
  'data-cy-state-searchable': 'searchable'
  'data-cy-handle-search-input': 'search-input'
  'data-cy-state-has-initial-options': 'has-initial-options'
  'data-cy-handle-initial-option': 'initial-option'
  'data-cy-handle-option': 'option'
  'data-cy-handle-close-btn': 'close-btn'
}

export default function DrawerTemplate(props: Props) {
  const handleQueryChange = (e) => props.onQuery && props.onQuery(e.target.value)
  const options = useFilterOptions(props.options, props.value, Boolean(props.query))
  return (
    <Wrapper className="DrawerTemplate">
      <ResetWrapper visible={Boolean(props.value.length)}>
        <button onClick={props.onResetClick} data-cy-handle={props['data-cy-handle-reset-btn']}>
          ZURÜCKSETZEN
        </button>
      </ResetWrapper>
      <div className="content">
        <div className="top">
          <div className="label">{props.label}</div>
        </div>

        {props.onQuery && (
          <div className="search" data-cy-state={props['data-cy-state-searchable']}>
            <input
              placeholder="Suche..."
              value={props.query}
              onChange={handleQueryChange}
              data-cy-handle={props['data-cy-handle-search-input']}
            />
            <FiSearch />
          </div>
        )}

        <div className="list-wrapper">
          <div className="lists">
            {Boolean(options.selectedOptions.length) && (
              <>
                <h5 data-cy-state={props['data-cy-state-has-initial-options']}>AUSGEWÄHLT</h5>
                <ul>
                  {options.selectedOptions.map((opt) => (
                    <Option
                      key={opt.label}
                      data-cy-handle={props['data-cy-handle-initial-option']}
                      onClick={() => props.onOptionClick(opt)}
                      selected={opt.selected}
                    >
                      {opt.selected ? <FiCheckSquare /> : <FiSquare />}
                      {opt.label}
                    </Option>
                  ))}
                </ul>
                <h5>OPTIONEN</h5>
              </>
            )}
            <ul>
              {options.selectableOptions.map((opt) => (
                <Option
                  key={opt.label}
                  data-cy-handle={props['data-cy-handle-option']}
                  onClick={() => props.onOptionClick(opt)}
                  selected={opt.selected}
                >
                  {opt.selected ? <FiCheckSquare /> : <FiSquare />}
                  {opt.label}
                </Option>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <button onClick={props.onClose} data-cy-handle={props['data-cy-handle-close-btn']}>
        {props.closeLabel}
      </button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: #323232;
  font-family: Raleway;

  > .content {
    background: white;
    flex: 1;
    padding: 12px 15px;
    display: flex;
    flex-direction: column;

    > .top {
      display: flex;
      justify-content: space-between;
      > .label {
        color: #262626;
        font-family: Raleway;
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 2.4px;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    > .search {
      border-bottom: 1px solid #323232;
      display: flex;
      align-items: center;
      padding-bottom: 2px;
      > input {
        color: #323232;
        line-height: 30px;
        flex: 1;
        font-size: 10px;
        letter-spacing: 2px;
        line-height: 25px;
        border: none;
        &:focus {
          outline: none;
        }
      }
      > svg {
        color: #323232;
        font-size: 20px;
      }
    }

    > .list-wrapper {
      position: relative;
      flex: 1;

      > .lists {
        overflow: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 10px;
        bottom: 0;

        > h5 {
          color: #323232;
          font-family: Raleway;
          font-size: 11px;
          letter-spacing: 2.4px;
          line-height: 25px;
          margin: 0;
          margin-top: 10px;
        }

        > ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }
      }
    }
  }

  > button {
    margin-top: 20px;
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2.36px;
    line-height: 40px;
    background-color: #555555;
  }
`

const ResetWrapper = styled.div`
  text-align: right;
  margin-bottom: 15px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  > button {
    color: #262626;
    font-family: Raleway;
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
    background: #eeeced;
    border: none;
    padding: 5px 10px;
  }
`

const Option = styled.li`
  color: ${(props) => (props.selected ? '#993351' : '#262626')};
  font-family: Raleway;
  font-size: 11px;
  letter-spacing: 1.2px;
  line-height: 20px;
  margin-bottom: 6.4px;

  > svg {
    margin-right: 10px;
    font-size: 14px;
    margin-bottom: -3px;
  }
`
