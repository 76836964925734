// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useFilter } from 'modules/products'
import posed, { PoseGroup } from 'react-pose'
import DrawerTemplate from '../DrawerTemplate'
import LabelTemplate from '../LabelTemplate'

type Props = {
  label: string
  identifier: string
  noSearch?: boolean
  filterKey: 'brand' | 'color' | 'shop' | 'size' | 'wunderCategories' | string
}

export default function Filter(props: Props) {
  const filter = useFilter(props.identifier, props.filterKey)
  const [open, setOpen] = React.useState(false)
  const options = React.useMemo(() => {
    const dict = {}
    for (const name of filter.data.value) dict[name] = true
    return filter.data.options.map((opt) => ({
      label: opt,
      selected: Boolean(dict[opt]),
    }))
  }, [filter.data.options, filter.data.value])
  const initialValue = React.useMemo(() => filter.data.value, [])
  const valueHasChanged = React.useMemo(() => {
    if (initialValue.length !== filter.data.value.length) {
      return true
    }
    for (const val of filter.data.value) {
      if (!initialValue.includes(val)) return true
    }
    return false
  }, [filter.data.value, initialValue])

  if (!filter.data.options.length && !filter.query) return null

  return (
    <div className="Filter" data-cy-collection="Filter">
      <LabelTemplate
        onClick={() => setOpen(true)}
        label={props.label}
        value={filter.data.value}
        data-cy-handle="preview-label"
        data-cy-state-selected="label-selected"
      />
      <PoseGroup>
        {open && (
          <Drawer key="drawer" data-cy-state="show-drawer">
            <DrawerTemplate
              label={props.label}
              onClose={() => setOpen(false)}
              options={options}
              query={filter.query}
              onQuery={props.noSearch ? undefined : filter.setQuery}
              onOptionClick={(opt) => filter.toggleFilterOption(opt.label)}
              value={filter.data.value}
              onResetClick={filter.clear}
              closeLabel={valueHasChanged ? 'ÜBERNEHMEN' : 'SCHLIESSEN'}
              data-cy-handle-reset-btn="reset-btn"
              data-cy-state-searchable="searchable"
              data-cy-handle-search-input="search-input"
              data-cy-state-has-initial-options="has-initial-options"
              data-cy-handle-initial-option="initial-option"
              data-cy-handle-option="option"
              data-cy-handle-close-btn="close-btn"
            />
          </Drawer>
        )}
      </PoseGroup>
    </div>
  )
}

const DrawerA = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

const Drawer = styled(DrawerA)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #f8f7f8;
  z-index: 10;
`
