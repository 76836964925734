import * as React from 'react'
import styled from 'styled-components'
import SortingFilter from './SortingFilter'
import Toggle from './Toggle'
import Filter from './Filter'
import PriceFilter from './PriceFilter'
import ColorFilter from './ColorFilter'
import CloseButton from './CloseButton'
import ResetButton from './ResetButton'
import { MdClose } from 'react-icons/md'
import { useDynamicFilterDefinitions } from 'modules/products'
import config from 'config'

type Props = {
  identifier: string
  onClose: () => void
}

export default function MobileDrawer(props: Props) {
  const dynamicFilterDefinitions = useDynamicFilterDefinitions(props.identifier)
  return (
    <Wrapper className="MobileDrawer" data-cy-collection="MobileDrawer">
      <button className="close" onClick={props.onClose} data-cy-handle="top-close-btn">
        FILTER SCHLIESSEN
        <MdClose />
      </button>
      <SortingFilter identifier={props.identifier} />
      <div className="filter-list">
        <ResetButton identifier={props.identifier} />
        <Filter label="GRÖSSE" filterKey="size" identifier={props.identifier} />
        <ColorFilter identifier={props.identifier} />
        <PriceFilter identifier={props.identifier} />
        <Filter label="MARKE" filterKey="brand" identifier={props.identifier} />
        <Filter label="SHOP" filterKey="shop" identifier={props.identifier} />
        {config.checkout.enabled && (
          <div className="directbuy">
            <Toggle
              big
              disabled
              identifier={props.identifier}
              tag="directbuy"
              data-cy-handle="sale-toggle"
              data-cy-state-active="sale-filter-active"
              label="NUR DIREKTKAUF ANZEIGEN"
            />
          </div>
        )}
        {dynamicFilterDefinitions.data.map((d) => (
          <Filter
            key={d.path}
            label={d.label}
            filterKey={d.path}
            identifier={props.identifier}
          />
        ))}
      </div>
      <div className="btn-wrapper">
        <div className="overflow" />
        <CloseButton
          data-cy-handle="bottom-close-btn"
          onClick={props.onClose}
          identifier={props.identifier}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: auto;
  height: 100%;
  > .close {
    padding: 15px;
    flex: 1;
    display: block;
    width: 100%;
    border: none;
    background: none;
    text-align: right;
    color: #323232;
    font-family: Raleway;
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
    cursor: pointer;
    > svg {
      background: white;
      border-radius: 20px;
      margin-left: 8px;
      padding: 3px;
      font-size: 20px;
      margin-bottom: -6px;
    }
  }

  > .filter-list {
    padding: 15px;
    margin-bottom: 80px;
    > * {
      margin-bottom: 15px;
    }

    > .directbuy {
      padding: 10px;
      background: white;
    }
  }

  > .btn-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    padding-top: 0;
    background: #eeeced;
    > .overflow {
      position: absolute;
      height: 40px;
      left: 0;
      right: 0;
      top: -40px;
      background: linear-gradient(180deg, rgba(238, 236, 237, 0), rgba(238, 236, 237, 1));
    }
  }
`
