// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useFilterValues } from 'modules/products'

type Props = {
  identifier: string
}

export default function ResetButton(props: Props) {
  const filterValues = useFilterValues(props.identifier)
  return (
    <Wrapper className="ResetButton">
      {filterValues.canReset && (
        <button
          data-cy-handle="clear-all"
          data-cy-state="can-clear-all"
          onClick={filterValues.clearFilterValues}
        >
          FILTER ZURÜCKSETZEN
        </button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: right;
  height: 30px;

  color: #262626;
  font-family: Raleway;

  > button {
    background: #eeeced;
    border: none;
    padding: 5px 10px;
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
  }
`
