// @flow
import * as React from 'react'
import styled from 'styled-components'
import { FiChevronRight } from 'react-icons/fi'

type Props = {
  onClick: () => void
  label: string
  value: string[]
  'data-cy-handle': string
  'data-cy-state-selected': string
}

export default function LabelTemplate(props: Props) {
  return (
    <Wrapper
      className="LabelTemplate"
      data-cy-handle={props['data-cy-handle']}
      onClick={props.onClick}
    >
      <span className="label">{props.label}</span>
      <div className="selected-wrapper">
        {!!props.value.length && (
          <span className="selected" data-cy-state={props['data-cy-state-selected']}>
            {props.value.map((val) => (
              <span key={val}>{val}</span>
            ))}
          </span>
        )}
      </div>
      <FiChevronRight />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 40px;
  background: white;
  padding: 0 10px 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Raleway;

  > .label {
    text-transform: uppercase;
    color: #262626;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 20px;
  }

  > .selected-wrapper {
    position: relative;
    flex: 1;
    height: 40px;
    > .selected {
      text-transform: uppercase;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      color: #555555;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 5px;
      > span {
        margin-left: 8px;
      }
    }
  }

  > svg {
    font-size: 20px;
  }
`
