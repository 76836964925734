import * as React from 'react'
import styled from 'styled-components'
import { useSorting } from 'modules/products'
import config from 'config'

type Props = {
  identifier: string
}

const translations = {
  [config.sortingAttributes.default]: 'Beste Ergebnisse',
  [config.sortingAttributes.priceAsc]: 'Niedrigster Preis',
  [config.sortingAttributes.priceDesc]: 'Höchster Preis',
}

export default function SortingFilter(props: Props) {
  const sorting = useSorting(props.identifier)
  return (
    <Wrapper className="SortingFilter">
      <div className="title">SORTIERUNG</div>
      <div className="options">
        {sorting.options.map((opt) => (
          <Option
            key={opt}
            data-cy-handle="sorting-option"
            onClick={() => sorting.setSorting(opt)}
            children={translations[opt]}
            active={opt === sorting.data}
          />
        ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 10px 15px;
  padding-bottom: 20px;

  > .title {
    color: #323232;
    font-family: Raleway;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2.4px;
    line-height: 25px;
  }

  > .options {
    margin-top: 10px;
    display: flex;
    overflow: auto;
  }
`

const Option = styled.button`
  padding: 5px 10px;
  margin-right: 10px;
  color: #a7a9ac;
  font-family: Raleway;
  font-size: 11px;
  letter-spacing: 2.4px;
  line-height: 20px;
  border: 1px solid #a7a9ac;
  cursor: pointer;
  background: none;

  &:focus {
    outline: none;
  }

  ${(props) =>
    props.active &&
    `
    border: 1px solid #993452;
    color: #993452;
  `}
`
