// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useProductList } from 'modules/products'

type Props = {
  onClick: () => void
  identifier: string
  'data-cy-handle': string
}

export default function CloseButton(props: Props) {
  const products = useProductList(props.identifier)
  const initialHits = React.useMemo(() => products.data, [])
  const changed = products.data !== initialHits
  return (
    <Wrapper
      className="CloseButton"
      data-cy-handle={props['data-cy-handle']}
      onClick={props.onClick}
    >
      {changed ? `ZEIGE ${products.numHits} ERGEBNISSE` : 'SCHLIESSEN'}
    </Wrapper>
  )
}

const Wrapper = styled.button`
  width: 100%;
  background: #555555;
  font-family: Raleway;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2.36px;
  line-height: 40px;
  color: white;
`
