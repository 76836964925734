// @flow
import * as React from 'react'
import styled from 'styled-components'
import { FiSquare, FiCheckSquare } from 'react-icons/fi'
import { useTags } from 'modules/products'

type Props = {
  identifier: string
  tag: 'sale' | 'directbuy'
  label: string
  disabled?: boolean
  big?: boolean
  'data-cy-handle': string
  'data-cy-state-active': string
}

export default function Toggle(props: Props) {
  const tags = useTags(props.identifier)
  const active = tags.data.includes(props.tag)
  const toggle = () => tags.toggleTag(props.tag)
  return (
    <Wrapper
      big={props.big}
      className="Toggle"
      disabled={props.disabled}
      active={active}
      data-cy-handle={props['data-cy-handle']}
      onClick={props.disabled ? undefined : toggle}
    >
      {active ? <FiCheckSquare data-cy-state={props['data-cy-state-active']} /> : <FiSquare />}
      <span>{props.label}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${props => props.disabled && `cursor:not-allowed;`}
  background: white;
  color: ${(props) => (props.active ? '#993350' : '#323232')};
  font-family: Raleway;
  font-size: 9px;
  letter-spacing: 2px;
  line-height: 16px;
  display: flex;
  align-items: center;

  > svg {
    font-size: 18px;
    margin-right: 10px;
  }

  ${(props) =>
    props.big &&
    `
    font-size: 11px;
    letter-spacing: 2.4px;
    line-height: 20px;
    > svg {
      margin-top: -3px;
    }
  `}
`
